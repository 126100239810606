<template>
  <div class="container">
    <div class="box" v-loading="loading">
      <div class="item" v-for="item in introduces" :key="item.Id">
        <div class="head">{{ item.Name }}</div>
        <el-descriptions class="descriptions-box" title="" :column="3" border>
          <template slot="title"></template>
          <el-descriptions-item
              :label="description.name"
              v-for="description in descriptions"
              :key="description.id">
            {{ item[description.field] }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="table-box">
          <el-table
              :data="item.ShopQuoteInfos"
              :header-row-style="headerStyle"
              :cell-style="cellStyle"
              border
              style="width: 100%; border: 1px solid #8CA7C9">
            <el-table-column :label="$t('introduce.range')" align="center">
              <template slot-scope="scope">
                <span>{{ `${scope.row.StartWeight}-${scope.row.EndWeight}` }}</span>
                <span v-if="scope.row.FirstWeight">{{ `（${ $t('introduce.firstWeight') }）` }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('introduce.unit')" align="center">
              <template>
                <span>KG</span>
              </template>
            </el-table-column>
            <el-table-column prop="UnitPrice" :label="$t('introduce.unitPrice')" align="center"></el-table-column>
            <el-table-column prop="SurchargeFormula" :label="$t('introduce.surchargeFormula')" align="center"></el-table-column>
          </el-table>
          <div class="descriptions">{{ item.Rem }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getIntroduces} from "@/api/system";
import { mapGetters } from "vuex"

export default {
  name: "introduce",
  methods: {
    headerStyle() {
      return {
        color: '#1E4374',
        fontSize: 'bold',
        borderColor: '#8CA7C9'
      }
    },
    cellStyle() {
      return {
        color: '#000',
        fontSize: 'bold',
        backgroundColor: '#F5F9FF',
        borderColor: '#8CA7C9'
      }
    },
    getData() {
      const params = {
        ShopId: this.shopID,
        TenantId: this.TenantId
      }
      this.loading = true
      getIntroduces(params).then(response => {
        this.loading = false
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.introduces = Data
        } else {
          this.$message.warning(Msg)
        }
      }).catch((err) => {
        this.$message.warning(err)
        this.loading = false
      })
    }
  },
  computed: {
    ...mapGetters(['shopID', 'TenantId']),
    descriptions() {
      return  [
        {
          id: 1,
          name: this.$t('introduce.destination'),
          field: 'DestinationNames'
        },
        {
          id: 2,
          name: this.$t('introduce.CalculationModeName'),
          field: 'CalculationModeName'
        },
        {
          id: 3,
          name: this.$t('introduce.CarryModeName'),
          field: 'CarryModeName'
        },
        {
          id: 4,
          name: this.$t('introduce.CarrierNames'),
          field: 'CarrierNames'
        },
        {
          id: 5,
          name: this.$t('introduce.GoodsTypeNames'),
          field: 'GoodsTypeNames'
        },
        {
          id: 6,
          name: this.$t('introduce.TimerNames'),
          field: 'TimerNames'
        },
        {
          id: 7,
          name: this.$t('introduce.FirstWeight'),
          field: 'FirstWeight'
        },
        {
          id: 8,
          name: this.$t('introduce.FirstPrice'),
          field: 'FirstPrice'
        },
        {
          id: 9,
          name: this.$t('introduce.FirstSurcharge'),
          field: 'FirstSurcharge'
        }
      ]
    }
  },
  created() {
    this.getData()
  },
  data() {
    return {
      loading: false,
      introduces: [],
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-descriptions-item__cell {
  text-align: center !important;
  border: 1px solid #8CA7C9 !important;
  color: #1E4374;
  font-weight: bold;
}
::v-deep .is-bordered-label {
  background-color: #ffffff;
}
::v-deep .el-descriptions-item__content {
  background-color: #F5F9FF;
}
::v-deep .el-table .el-table__cell {
  border-color: #8CA7C9 !important;
}
.container {
  min-height: calc(100vh - (10rem + 29rem));
  width: 60%;
  margin: 2rem auto;
  background-color: #fff;
  padding: 2rem;
  .box {
    .item {
      margin: 2rem 0;
      border: 1px solid #A3B8D2;
      .head {
        width: 100%;
        text-align: center;
        color: #093267;
        padding: 1rem 0;
        font-weight: bold;
        font-size: 1.8rem;
      }
      .table-box {
        margin-top: 1rem;
        padding: 0 2rem;
        .descriptions {
          padding: 2rem;
          font-weight: bold;
          color: #C1553D;
          width: 100%;
          text-align: center;
          font-size: 1.6rem;
        }
      }
    }
  }
}
</style>
